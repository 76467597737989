import React from "react";

function App() {
  return (
    <div
      style={{
        background: "#111",
        height: "100%",
        gap: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <span style={{ fontSize: "26px" }}>Guilherme Kammsetzer</span>
      </div>

      <span>Frontend web developer</span>

      <span>guilherme@kammsetzer.dev</span>
    </div>
  );
}

export default App;
